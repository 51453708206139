<template>
  <div class="ui-rating">
    <img :style="{'height': size + 'px', width: size + 'px'}" class="ui-rating__star-full" v-for="(item, key) in Math.floor(value)" :key="item[key]" src="@/assets/svg/icon-star-active.svg" alt="" />
    <img :style="{'height': size + 'px', width: size + 'px'}" class="ui-rating__star-half" v-if="value-Math.floor(value) === 0.5" src="@/assets/svg/icon-start-half.svg" alt="" />
    <img :style="{'height': size + 'px', width: size + 'px'}" class="ui-rating__star-empty" v-for="(item, key) in (5 - Math.round(value))" :key="key" src="@/assets/svg/icon-star-empty.svg" alt="" />
  </div>

</template>

<script>
export default {
  name: "UiRating",

  props: {
    value: {
      type: Number,
      default: 0,
    },
    size: {
      type: [Number, String],
      default: 50
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-rating {
  display: flex;
  gap: 5px;
}
</style>
